import { Component, OnInit } from '@angular/core';
import { AuthenticationService, BlockUiService } from 'core';
import { Empleado } from 'core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  menu_items: MenuItem[];
  empleado: Empleado;

  constructor(
    private authenticationService: AuthenticationService, private blockUiService: BlockUiService,
    private router: Router, private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.empleado = this.authenticationService.authentication.user as Empleado;

    if(this.empleado.verRegistros){
      this.menu_items = [
        {
          label: '',
          routerLink: '/registros',
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: '',
          routerLink: '/incidencias',
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: '',
          routerLink: '/ausencias',
          routerLinkActiveOptions: { exact: true }
        }
      ];
      this.translateService.get('app.menu.registros').subscribe((texto: string) => {
        this.menu_items[0].label = texto;
      });

      this.translateService.get('app.menu.incidencias').subscribe((texto: string) => {
        this.menu_items[1].label = texto;
      });
      this.translateService.get('app.menu.ausencias').subscribe((texto: string) => {
        this.menu_items[2].label = texto;
      });
      this.router.navigate(['/registros']);
    }else{
      this.menu_items = [
        {
          label: '',
          routerLink: '/incidencias',
          routerLinkActiveOptions: { exact: true }
        },
        {
          label: '',
          routerLink: '/ausencias',
          routerLinkActiveOptions: { exact: true }
        }
      ];
      this.translateService.get('app.menu.incidencias').subscribe((texto: string) => {
        this.menu_items[0].label = texto;
      });
      this.translateService.get('app.menu.ausencias').subscribe((texto: string) => {
        this.menu_items[1].label = texto;
      });
      this.router.navigate(['/incidencias']);
    }

  }

  logOut() {
    this.blockUiService.ejecutarConBloqueo(new Promise((resolve, reject) => {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
      resolve();
    }));
  }

}
