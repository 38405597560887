import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private translateService: TranslateService, private titleService: Title) {
      registerLocaleData(localeEs, 'es-ES', localeEsExtra);

      translateService.setDefaultLang('es');
      translateService.use(translateService.getDefaultLang());

      titleService.setTitle('FayTime');
  }

  ngOnInit() {
    this.translateService.get('app.titulo').subscribe((titulo: string) => {
      this.titleService.setTitle(titulo);
    });
  }

}
