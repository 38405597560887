import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from 'core';

import { MainPageComponent } from './pages/main-page/main-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ListaIncidenciasComponent, GraficoRegistrosComponent, ListaAusenciasComponent } from 'core';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'registros',
        component: GraficoRegistrosComponent
      },
      {
        path: 'incidencias',
        component: ListaIncidenciasComponent
      },
      {
        path: 'ausencias',
        component: ListaAusenciasComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent
  },
  {
    path: 'not-found',
    component: NotFoundPageComponent
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
