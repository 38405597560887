import { CoreModule, LoadingModule, BlockUiModule, ErrorHttpInterceptor, OauthHttpInterceptor, AuthenticationService } from 'core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MatIconModule } from '@angular/material/icon';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { ResumenPerfilComponent } from './components/perfil/resumen-perfil/resumen-perfil.component';
import { RegistroRapidoComponent } from './components/registro/registro-rapido/registro-rapido.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ForbiddenPageComponent,
    NotFoundPageComponent,
    MainPageComponent,
    ResumenPerfilComponent,
    RegistroRapidoComponent
  ],
  imports: [
    CoreModule.forRoot({
        api_url: environment.api_url,
        oauth2_client_id: environment.oauth2_client_id,
        oauth2_client_secret: environment.oauth2_client_secret,
        authentication_session_encrypt_key: environment.session_encrypt_key,
        authentication_roles_all: ['ROLE_USUARIO', 'ROLE_USUARIO_EMPLEADO'],
        authentication_login_page: '/login',
        authentication_forbidden_page: '/forbidden',
        default_locale: environment.default_locale
    }),
    LoadingModule,
    BlockUiModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    ToastModule,
    ButtonModule,
    MenubarModule,
    CardModule,
    TableModule,
    DropdownModule,
    MatIconModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OauthHttpInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [AuthenticationService],
      useFactory: (authenticationService) => authenticationService.getLocale()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
