import { Component, OnInit, EventEmitter } from '@angular/core';
import { BlockUiService, Registro, TipoRegistro, RegistroService, EventEmiterService, UtilService, AuthenticationService, Empleado, SubtipoRegistro, SubtipoRegistroService, Icono, IconoService } from 'core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, SelectItem } from 'primeng/api';

interface Tiempo {
  horas: number,
  minutos: number,
  segundos: number
}

@Component({
  selector: 'app-registro-rapido',
  templateUrl: './registro-rapido.component.html',
  styleUrls: ['./registro-rapido.component.scss']
})
export class RegistroRapidoComponent implements OnInit {
  registro_actual: Registro;
  registro_actual_duracion: Tiempo;
  registro_actual_duracion_interval;
  localizando_registro: boolean;
  subtipos_registro_map: {[key: number]: SubtipoRegistro};
  subtipos_select_items: SelectItem[];
  iconos_map: {[key: number]: Icono};
  subtipo_seleccionado_id: number;

  constructor(
    private blockUiService: BlockUiService, private registroService: RegistroService,
    private subtipoRegistroService: SubtipoRegistroService, private iconoService: IconoService,
    private eventEmiterService: EventEmiterService, private utilService: UtilService,
    private translateService: TranslateService, private messageService: MessageService,
    private authenticationService: AuthenticationService
  ) {
    this.registro_actual = null;
    this.registro_actual_duracion = null;
    this.localizando_registro = false;
    this.subtipo_seleccionado_id = null;
  }

  ngOnInit() {
    this.cargarSubtiposRegistro();
    this.cargarDatosRegistroActual();
  }

  private cargarSubtiposRegistro() {
    this.translateService.get('app.registro_rapido.selecciona_tipo').subscribe((seleccionaTipoMsg: string) => {
      this.subtipos_registro_map = {};
      this.subtipos_select_items = [{
        label: seleccionaTipoMsg,
        value: null
      }];
      this.iconos_map = {};
      this.subtipoRegistroService.getTiposRegistro().subscribe((subtipos_registro: SubtipoRegistro[]) => {
        for (let subtipo_registro of subtipos_registro) {
          if (this.subtipos_registro_map[subtipo_registro.id]  == null) {
            this.subtipos_registro_map[subtipo_registro.id] = subtipo_registro;

            this.subtipos_select_items.push({
              label: subtipo_registro.nombre,
              value: subtipo_registro.id
            });
          }

          if (this.iconos_map[subtipo_registro.icono] == null) {
            this.iconoService.getIcono(subtipo_registro.icono, true).subscribe((icono: Icono) => {
              this.iconos_map[subtipo_registro.icono] = icono;
            });
          }
        }
      });
    });
  }

  private cargarDatosRegistroActual() {
    //Cargamos los datos del último registro
    this.registroService.getUltimoRegistro().subscribe((registro) => {
      if (registro == null) {
        this.registro_actual = {
          tipo: TipoRegistro.Salida
        } as Registro;
      } else {
        this.registro_actual = registro;
      }
      this.subtipo_seleccionado_id = null;

      this.cargarDuracionRegistroActual();
    });
  }

  private cargarDuracionRegistroActual() {
    if (this.registro_actual == null || this.registro_actual.tipo === TipoRegistro.Salida || this.registro_actual.fecha == null) {
      this.registro_actual_duracion = null;
    } else {
      const duracion = {
      } as Tiempo;

      let duracion_segundos_totales = Math.floor((Date.now() - new Date(this.registro_actual.fecha).getTime()) / 1000)
      let factor = 60 * 60;
      duracion.horas = Math.floor(duracion_segundos_totales / factor);
      duracion_segundos_totales -= duracion.horas * factor;
      factor = 60;
      duracion.minutos = Math.floor(duracion_segundos_totales / factor);
      duracion_segundos_totales -= duracion.minutos * factor;
      duracion.segundos = duracion_segundos_totales;
      this.registro_actual_duracion = duracion;
    }

    this.actualizarProcesoActualizacionDuracionRegistroActual();
  }

  private actualizarProcesoActualizacionDuracionRegistroActual() {
    if (this.registro_actual_duracion == null) {
      this.pararProcesoActualizacionDuracionRegistroActual();
    } else {
      this.iniciarProcesoActualizacionDuracionRegistroActual();
    }
  }

  private iniciarProcesoActualizacionDuracionRegistroActual() {
    if (this.registro_actual_duracion_interval == null) {
      this.registro_actual_duracion_interval = setInterval(() => {
        this.cargarDuracionRegistroActual();
      },1000);
    }
  }

  private pararProcesoActualizacionDuracionRegistroActual() {
    if (this.registro_actual_duracion_interval != null) {
      clearInterval(this.registro_actual_duracion_interval);
      this.registro_actual_duracion_interval = null;
    }
  }

  private anyadirRegistro(tipo: TipoRegistro) {
    this.blockUiService.ejecutarConBloqueo(new Promise(async (resolve, reject) => {
      let position = null;
      let error_localizacion = false;

      const empleado = this.authenticationService.authentication.user as Empleado;

      if (empleado.localizar_registros) {
        try {
          this.localizando_registro = true;

          position = await this.utilService.getLocalizacion();

          this.localizando_registro = false;
        } catch (error) {
          error_localizacion = true;
          this.localizando_registro = false;
          this.translateService.get('app.registro_rapido.localizacion.sin_permisos').subscribe((message: string) => {
            this.messageService.add({
              severity: 'error',
              summary: message
            });
          });
        }
      }

      if (error_localizacion) {
        resolve();
      } else {
        const registro = {
          tipo: tipo,
          subtipo: this.subtipo_seleccionado_id
        } as Registro;

        if (position != null) {
          registro.localizacion = {
            latitud: position.coords.latitude,
            longitud: position.coords.longitude
          }
        }

        this.registroService.anyadirRegistro(registro).toPromise().then(() => {
          resolve();
          this.eventEmiterService.registroActualOnChange.emit(null);
          this.cargarDatosRegistroActual();
        }).catch(() => {
          resolve();
        });
      }
    }));
  }

  get iniciar_enabled(): boolean {
    return this.registro_actual != null && (this.registro_actual.tipo === TipoRegistro.Salida || this.subtipo_seleccionado_id != null);
  }

  get parar_enabled(): boolean {
    return this.registro_actual != null && this.registro_actual.tipo === TipoRegistro.Entrada && this.subtipo_seleccionado_id == null;
  }

  iniciar() {
    if (this.iniciar_enabled) {
      this.anyadirRegistro(TipoRegistro.Entrada);
    }
  }

  parar() {
    if (this.parar_enabled) {
      this.anyadirRegistro(TipoRegistro.Salida);
    }
  }

}
