import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FormService, AuthenticationService, BlockUiService } from 'core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  login_form: FormGroup;

  constructor(private formBuilder: FormBuilder, private formService: FormService,
              private authenticationService: AuthenticationService, private blockUiService: BlockUiService,
              private translateService: TranslateService, private messageService: MessageService,
              private router: Router) { }

  ngOnInit() {
    this.login_form = this.formBuilder.group({
      codigo_empresa: new FormControl('', Validators.required),
      nif: new FormControl('', Validators.required),
      codigo_empleado: new FormControl('', Validators.required)
    });
  }

  onLoginSubmit() {
    this.blockUiService.ejecutarConBloqueo(new Promise((resolve, reject) => {
      this.formService.onSubmitFormValidation(this.login_form, 'app.login.', () => {
        const codigo_empresa = this.login_form.value.codigo_empresa;
        const nif = this.login_form.value.nif;
        const codigo_empleado = this.login_form.value.codigo_empleado;

        this.authenticationService.loginEmpleado(codigo_empresa, nif, codigo_empleado).then(success => {
          if (success) {
            this.router.navigate(['/']);
          } else {
            this.translateService.get('app.error.login').subscribe((message: string) => {
              this.messageService.add({
                severity: 'error',
                summary: message
              });
            });
          }

          resolve();
        }).catch(error => {
          reject();
        });
      }, () => {
        reject();
      });
    }));
  }

}
