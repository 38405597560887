import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'core';

@Component({
  selector: 'app-resumen-perfil',
  templateUrl: './resumen-perfil.component.html',
  styleUrls: ['./resumen-perfil.component.scss']
})
export class ResumenPerfilComponent implements OnInit {

  authenticationService: AuthenticationService;
  fecha: Date;

  constructor(private _authenticationService: AuthenticationService) {
    this.authenticationService = _authenticationService;
    this.fecha = new Date();
  }

  ngOnInit() {
  }

}
